import React from 'react';
import { Link } from 'react-router-dom';

const CourseOnboarding = () => {
  return (
    <div className="course-onboarding">
      <Link to="/Account/Login/Business-for-teams" className="back-button">
        &lt; Back to Login
      </Link>
      <div className="header">Hi, Welcome on board</div>
      <div className="content">
        Your course creation is in progress. Check your provided email for more information.
        <br />
        Start date of course is 6th June, 2024.
        <br />
        Duration: Max is 4-8 hours depending on your course. You can however finish it at a sitting.
      </div>
      <div className="congrats-header">Congrats, welcome on board!</div>
    </div>
  );
};

export default CourseOnboarding;
