import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { getFirestore, serverTimestamp } from 'firebase/firestore';
import logo from '../../assets/images/Lecturemeet.png';

const BusinessLogin = () => {
  const navigate = useNavigate();
  const [businessId, setBusinessId] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    if (!businessId || !email) {
      setError('Both fields are required.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const db = getFirestore();
      const docRef = doc(db, 'Business-Registration', businessId, 'Onboard-Teams', businessId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        setError('Business ID not found.');
        setLoading(false);
        return;
      }

      const data = docSnap.data();
      const teamMember = data.teamData.find(member => member.email === email);

      if (!teamMember) {
        setError('Team Member not found for this business. Contact your team HR.');
        setLoading(false);
        return;
      }

      let userId = teamMember.userId || generateUserId();

      const userQuery = query(collection(db, 'Business-Team-Users'), where('email', '==', teamMember.email));
      const userQuerySnapshot = await getDocs(userQuery);

      if (!userQuerySnapshot.empty) {
        userId = userQuerySnapshot.docs[0].data().userId;
      }

      const userRef = doc(db, 'Business-Team-Users', userId);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        await setDoc(userRef, {
          fullName: teamMember.fullName,
          email: teamMember.email,
          profileImage: teamMember.profileImage || '',
          businessId,
          userId,
          organisationName: data.organisationName || '',
          loggedInAt: serverTimestamp()
        });
      }

      navigate(`/User/Organisation/Dashboard/${businessId}/${encodeURIComponent(teamMember.fullName)}/${userId}`, {
        state: {
          fullName: teamMember.fullName,
          email: teamMember.email,
          businessId,
          organisationName: data.organisationName || '',
          userId
        }
      });

    } catch (error) {
      setError('An error occurred during login. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const generateUserId = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };

  return (
    <section className="login-section" style={{ maxWidth: '400px', margin: '10% auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Link to='/'>
          <img src={logo} alt="LectureMeet Logo" style={{ height: '200px' }} />
        </Link>
        <h2 style={{ textAlign: 'center' }}>Login For Teams</h2>
      </div>
      <label style={{ display: 'block', marginBottom: '5px', textAlign: 'left' }}>Organisation Business ID:</label>
      <input type="text" placeholder='Your company 🆔' value={businessId} onChange={(e) => setBusinessId(e.target.value)} style={{ width: '100%', padding: '5px' }} required />
      
      <label style={{ display: 'block', marginBottom: '5px', textAlign: 'left' }}>Team Member Email:</label>
      <input type="email" placeholder='Enter email 📧' value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: '100%', padding: '5px' }} required />
      {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
      <button onClick={handleLogin} disabled={loading} style={{ width: '100%', padding: '8px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
        {loading ? 'Logging in...' : 'Login'}
      </button>

      <br />
      <br />
      <h4><Link to="/Account/Login/Admin-flow" className="back-button" style={{ width: '100%', padding: '8px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Manage Account/Admin</Link></h4>
      <h4><Link to="/" className="back-button">&lt; Go back</Link></h4>
    </section>
  );
};

export default BusinessLogin;
