import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/Lecturemeet.png';

const SignupSection = () => {
  return (
    <section className="signup-section">
      <div className="container">
        <div className="signup-options">
          <div className="logo-container">
            <Link to='./'>
            <img src={logo} alt="LectureMeet Logo" />
            </Link>
          </div>
          <h2>Create your learning account now</h2>
          <p>In getting started, create an account</p>
          <div className="signup-links">
            <Link to="/Account/Create/Business-signup">Signup For Teams</Link>
            <Link to="https://pay-per-course.lecturemeet.com" target='_blank'>Pay Per Course</Link>
          </div>
          <br />
          <br />
          <div className="login-option">
            <p>Already a member? <Link to="/Account/Login/GetStarted">Login</Link></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignupSection;
