import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, getDocs, updateDoc, collection, addDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

const SelectCourse = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const businessId = location.pathname.split('/')[5];
  const fullName = decodeURIComponent(location.pathname.split('/')[6]);
  const userId = location.pathname.split('/')[7];
  const email = location.state ? location.state.email : '';

  const [courses, setCourses] = useState([]);
  const [organizationName, setOrganizationName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const db = getFirestore();
        const docRef = doc(db, 'Business-Registration', businessId, 'Onboard-Teams', businessId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setOrganizationName(data.organizationName);
          setCourses(data.selectedCourses || []); // Ensure selectedCourses is initialized even if it's not present
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document: ', error);
      }
    };

    fetchOrganizationData();
  }, [businessId]);

  const handleCourseClick = async (courseName) => {
    try {
      setLoading(true); // Set loading to true when redirecting

      const db = getFirestore();
      const teamCourseStartRef = collection(db, 'Business-Registration', businessId, 'Team-Course-Start');
      const querySnapshot = await getDocs(teamCourseStartRef);
      const existingCourse = querySnapshot.docs.find(doc => doc.data().email === email && doc.data().course === courseName);

      if (existingCourse) {
        // Course already selected, navigate directly to the course
        navigate(`/User/Organisation/Course/${courseName}?businessId=${businessId}&userName=${fullName}`, {
          state: {
            fullName,
            course: courseName,
            organizationName,
            businessId,
            email,
          }
        });
        setLoading(false); // Set loading to false after navigation
        return;
      }

      const date = new Date().toISOString().split('T')[0];
      const courseData = {
        email,
        fullName,
        course: courseName,
        date,
        dateOfCommencement: date,
        organizationName,
        businessId,
      };

      await addDoc(teamCourseStartRef, courseData);

      // Update user in Business-Team-Users collection with selected course and start date
      const userRef = doc(db, 'Business-Team-Users', userId);
      await updateDoc(userRef, {
        selectedCourse: courseName,
        courseStartDate: date,
        email, // Include email in the user data
      });

      // Save selected course in localStorage
      localStorage.setItem('selectedCourse', JSON.stringify({ courseName, businessId, fullName, userId }));

      // Pass details to the redirect
      navigate(`/User/Organisation/Course/${courseName}?businessId=${businessId}&userName=${fullName}`, {
        state: {
          fullName,
          course: courseName,
          organizationName,
          businessId,
        }
      });
    } catch (error) {
      console.error('Error adding document: ', error);
      setLoading(false); // Set loading to false if an error occurs
    }
  };

  return (
    <div>
      <div className="user-info">
        <h4>Name Organization: {organizationName}</h4>
        <h4>Business ID: {businessId}</h4>
        <h4>User Name: {fullName}</h4>
        <hr />
      </div>
      <div>
        <h2>Select your course to get started</h2>
      </div>
      <div className="course-columns">
        {loading ? ( // Display loading spinner if loading is true
          <div className="loading-spinner"></div>
        ) : (
          courses.map((course, index) => (
            <div key={index} className="course" onClick={() => handleCourseClick(course)}>
              {course}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SelectCourse;
