import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc, serverTimestamp, collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';

const defaultProfileImage = 'https://cdn-icons-png.flaticon.com/128/3024/3024605.png';

const useProfileData = (userId, businessId) => {
  const [profileData, setProfileData] = useState(null);
  const [organisationName, setOrganisationName] = useState('');

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const db = getFirestore();
        const userRef = doc(db, 'Business-Team-Users', userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          setProfileData(userData);

          const businessRef = doc(db, 'Business-Registration', businessId);
          const businessSnap = await getDoc(businessRef);

          if (businessSnap.exists()) {
            const businessData = businessSnap.data();
            setOrganisationName(businessData.organisationName);
          } else {
            console.log('Business document not found');
          }
        } else {
          console.log('User document does not exist');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchProfileData();
  }, [userId, businessId]);

  return { profileData, organisationName };
};

const useCoursesTaken = (businessId, userName) => {
  const [coursesTaken, setCoursesTaken] = useState([]);

  useEffect(() => {
    const fetchCoursesTaken = async () => {
      try {
        const db = getFirestore();
        const coursesRef = collection(db, 'Certificate-Business-Team-learners', `${businessId}_${userName}`);
        const querySnapshot = await getDocs(coursesRef);
        const courses = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          courses.push({ course: data.course, certificateNumber: data.certificateNumber });
        });
        setCoursesTaken(courses);
      } catch (error) {
        console.error('Error fetching courses taken:', error);
      }
    };

    fetchCoursesTaken();
  }, [businessId, userName]);

  return coursesTaken;
};

const Dashboard = () => {
  const { businessId, userName, userId } = useParams();
  const navigate = useNavigate();

  const { profileData, organisationName } = useProfileData(userId, businessId);
  const coursesTaken = useCoursesTaken(businessId, userName);
  const [profileImage, setProfileImage] = useState(defaultProfileImage);

  useEffect(() => {
    if (profileData?.profileImage) {
      setProfileImage(profileData.profileImage);
    }
  }, [profileData]);

  const handleLogout = async () => {
    try {
      const db = getFirestore();
      const userRef = doc(db, 'Business-Team-Users', userId);
      await updateDoc(userRef, {
        loggedInAt: serverTimestamp()
      });
      navigate('/Account');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const dataURL = e.target.result;

      const storage = getStorage();
      const storageRef = ref(storage, `profileImages/${businessId}/${userId}/${file.name}`);

      try {
        const snapshot = await uploadString(storageRef, dataURL, 'data_url');
        const downloadURL = await getDownloadURL(snapshot.ref);

        const db = getFirestore();
        const userRef = doc(db, 'Business-Team-Users', userId);
        await updateDoc(userRef, {
          profileImage: downloadURL
        });

        setProfileImage(downloadURL);
      } catch (error) {
        console.error('Error uploading profile image:', error);
      }
    };
    reader.readAsDataURL(file);
  };


  return (
    <section style={{ display: 'flex' }}>
      <aside style={{ width: '250px', padding: '20px', backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <label htmlFor="profileImageInput">
          <img src={profileImage} alt="Profile" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
          <button style={{ background: 'none', border: 'none', cursor: 'pointer', marginTop: '10px' }} onClick={() => document.getElementById('profileImageInput').click()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-camera" viewBox="0 0 16 16">
              <path d="M10.5 7a.5.5 0 0 1 .5.5V8a.5.5 0 0 1-.5.5H8v2.5a.5.5 0 0 1-1 0V8.5H5a.5.5 0 0 1 0-1h2V5a.5.5 0 0 1 1 0v2h2.5z"/>
              <path d="M14 2H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM2 1h12a1 1 0 0 1 1 1v3H1V2a1 1 0 0 1 1-1zm0 13a1 1 0 0 1-1-1V6h14v7a1 1 0 0 1-1 1H2z"/>
            </svg>
          </button>

          <input id="profileImageInput" type="file" accept="image/*" style={{ display: 'none' }} onChange={handleProfileImageChange} />
        </label>
        <Link to={`/User/Organisation/Course/Select-course/${businessId}/${encodeURIComponent(userName)}/${userId}`} style={{ textDecoration: 'none', margin: '20px 0' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-journals" viewBox="0 0 16 16">
            <path d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2"/>
            <path d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0"/>
          </svg> Get Course
        </Link>
        <button onClick={handleLogout} style={{ background: 'black', border: 'none', cursor: 'pointer' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M6.364 12.364a.5.5 0 0 1 0-.708L9.05 9H1.5a.5.5 0 0 1 0-1H9.05L6.364 4.344a.5.5 0 1 1 .707-.707l3.5 3.5a.5.5 0 0 1 0 .707l-3.5 3.5a.5.5 0 0 1-.707 0z"/>
            <path fillRule="evenodd" d="M10 15a2 2 0 0 0 2-2V8a.5.5 0 0 1 1 0v5a3 3 0 0 1-3 3H2a3 3 0 0 1-3-3V2a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v4a.5.5 0 0 1-1 0V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h8z"/>
          </svg> Logout
        </button>
      </aside>
      <main style={{ flexGrow: 1, padding: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>Welcome, {userName}</h2>
          <p>{profileData?.email}</p>
          <p>Your Organisation ID: {profileData?.businessId}</p>
          <p>{organisationName}</p>
        </div>
        <hr style={{ margin: '20px 0' }} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {coursesTaken.length > 0 ? (
            coursesTaken.map((course, index) => (
              <div key={index} style={{ background: '#e0e0e0', padding: '10px', borderRadius: '5px', cursor: 'pointer' }} onClick={() => navigate(`/User/Organisation/Course/${course.course}?businessId=${businessId}&userName=${userName}`, { state: { businessId, userId } })}>
                {course.course} - Certificate No: {course.certificateNumber}
              </div>
            ))
          ) : (
            <p>Welcome onboard <strong>{userName}</strong> Get started or continue with <br />your course by clicking the Get Course button on the left side.</p>
          )}
        </div>
      </main>
    </section>
  );
};

export default Dashboard;
