import React from 'react';
import { useLocation } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';

const PaymentSuccessPage = () => {
  const location = useLocation();
  const { paymentDetails } = location.state || {};

  const formatDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toLocaleString();
    }
    return new Date(timestamp).toLocaleString();
  };

  if (!paymentDetails) {
    return <div>Error: Payment details not found.</div>;
  }

  const sectionStyle = {
    padding: '20px',
    margin: '20px auto',
    maxWidth: '600px',
    border: '1px solid #ddd',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    fontFamily: 'Arial, sans-serif',
  };

  const labelStyle = {
    fontWeight: 'bold',
    display: 'block',
    marginTop: '10px',
  };

  const valueStyle = {
    display: 'block',
    marginBottom: '10px',
  };

  const headingStyle = {
    textAlign: 'center',
    color: '#121481',
  };

  return (
    <section style={sectionStyle} className="payment-success">
      <h2 style={headingStyle}>Payment Success</h2>
      <div>
        <label style={labelStyle}>Organization Name:</label>
        <span style={valueStyle}>{paymentDetails.organizationName}</span>
      </div>
      <div>
        <label style={labelStyle}>Amount Paid:</label>
        <span style={valueStyle}>{paymentDetails.amount}</span>
      </div>
      <div>
        <label style={labelStyle}>Reference:</label>
        <span style={valueStyle}>{paymentDetails.reference}</span>
      </div>
      <div>
        <label style={labelStyle}>Date of Payment:</label>
        <span style={valueStyle}>{formatDate(paymentDetails.dateOfPayment)}</span>
      </div>
      <div>
        <label style={labelStyle}>Expiration Date:</label>
        <span style={valueStyle}>{formatDate(paymentDetails.expirationDate)}</span>
      </div>
      <div>
        <label style={labelStyle}>Purpose:</label>
        <span style={valueStyle}>{paymentDetails.purpose}</span>
      </div>
      <div>
        <label style={labelStyle}>Title:</label>
        <span style={valueStyle}>{paymentDetails.title}</span>
      </div>
    </section>
  );
};

export default PaymentSuccessPage;
