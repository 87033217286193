import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { collection, addDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDqOJIIVMikxNfvkMD68lz0TkODpcLhjtQ",
  authDomain: "business-lecturemeet.firebaseapp.com",
  projectId: "business-lecturemeet",
  storageBucket: "business-lecturemeet.appspot.com",
  messagingSenderId: "983569980918",
  appId: "1:983569980918:web:f360ec6f8f0fae65003bd7",
  measurementId: "G-KXLSXKTWPE"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const BusinessSignup = () => {
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [workEmail, setWorkEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [hrAdminContact, setHrAdminContact] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [teamSize, setTeamSize] = useState('');
    const [consentToContact, setConsentToContact] = useState(false);
    const [trainingNeeds, setTrainingNeeds] = useState('');
  
    const handleSignup = async () => {
      try {
        // Check if all required fields are filled
        if (!fullName || !workEmail || !phoneNumber || !hrAdminContact || !jobTitle || !organizationName || !teamSize || !consentToContact) {
          alert('Please fill in all required fields.');
          return;
        }
  
        // Validate work email format
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(workEmail)) {
          alert('Please enter a valid work email.');
          return;
        }
  
        // Generate unique business ID
        const businessId = generateBusinessId();
  
        // Save user data to Firestore
        await addDoc(collection(db, 'Business-Registration'), {
          fullName,
          workEmail,
          phoneNumber,
          hrAdminContact,
          jobTitle,
          organizationName,
          teamSize,
          consentToContact,
          businessId,
          trainingNeeds // Include training needs in Firestore
        });
  
        // Redirect to global signup page with unique URL number and business ID
        const uniqueUrlNumber = Math.floor(Math.random() * 1000000); // Generate random URL number
        navigate(`/User/Organisation/global-signup/${uniqueUrlNumber}?businessId=${businessId}`);
      } catch (error) {
        console.error(error.message);
      }
    };
  

  const generateBusinessId = () => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    let id = '';
  
    // Generate first three letters
    for (let i = 0; i < 3; i++) {
      id += letters.charAt(Math.floor(Math.random() * letters.length));
    }
  
    // Generate four unique numbers
    for (let i = 0; i < 4; i++) {
      id += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
  
    // Mix capital and small letters
    id = id.split('').map(char => Math.random() < 0.5 ? char.toUpperCase() : char.toLowerCase()).join('');
  
    return id;
  };
  

  return (
    <section className="signup-section">
      <h2>Lecturemeet <span className='text-black'>for Teams</span></h2>
      <input type="text" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} required />
      <input type="text" placeholder="Organization Name" value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} required />
      <input type="email" placeholder="Work Email" value={workEmail} onChange={(e) => setWorkEmail(e.target.value)} required />
      <input type="text" placeholder="Phone Number +1" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
      <input type="text" placeholder="HR/Admin Contact" value={hrAdminContact} onChange={(e) => setHrAdminContact(e.target.value)} required />
      <input type="text" placeholder="Job Title" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} required />
      <input type="text" placeholder="Organization Name" value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} required />
      <select value={teamSize} onChange={(e) => setTeamSize(e.target.value)} required>
        <option value="1-10">1-10</option>
        <option value="11-50">11-50</option>
        <option value="51-100">51-100</option>
        <option value="100+">100+</option>
      </select>
      <label>
      <textarea type="text" placeholder="What are your organization's training needs? (Optional)" value={trainingNeeds} onChange={(e) => setTrainingNeeds(e.target.value)}></textarea>
      <br />
        <input type="checkbox" checked={consentToContact} onChange={(e) => setConsentToContact(e.target.checked)} required />
       I have read the terms and policies and also consent for contact
      </label>
      <br />
      <br />
     
      <button onClick={handleSignup}>Create Organisation</button>
    </section>
  );
};

export default BusinessSignup;
