import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Account from './Account/Account';
import Businesssignup from './Account/Create/Business-signup';
import Paypercoursesignup from './Account/Create/Pay-per-course-signup';
import GetStarted from './Account/Login/GetStarted';
import Businessforteams from './Account/Login/Business-for-teams';

import NotFound from './User/NotFound';



// For teams

import Dashboard from './User/Organisation/Dashboard';
import Globalsignup from './User/Organisation/global-signup';
import Renewplan from './User/Organisation/Renew-plan';
import Updateorganisation from './User/Organisation/Update-organisation';
import Pay from './User/Organisation/Pay';
import PaymentSuccess from './User/Organisation/Payment-Success';
import CreatingCourse from './User/Organisation/Creating-Course';
import Profile from './User/Organisation/Profile';




import Certificate from './User/Organisation/Course/Certificate';
import CourseDashboard from './User/Organisation/Course/CourseDashboard';
import Selectcourse from './User/Organisation/Course/Select-course';




function AppRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Account />} />
      <Route path="/Account" element={<Account />} />
      <Route path="/Account/Create/Business-signup" element={<Businesssignup />} />
      <Route path="/Account/Create/Pay-per-course-signup" element={<Paypercoursesignup />} />
      <Route path="/Account/Login/GetStarted" element={<GetStarted />} />
      <Route path="/Account/Login/Business-for-teams" element={<Businessforteams />} />
  
      
      
    

 
 
      <Route path="/User/Organisation/Course/Certificate/:businessId/:courseName/:userName" element={<Certificate />} />
      <Route path="/User/Organisation/Dashboard/:businessId/:userName/:userId" element={<Dashboard />} />
      <Route path="/User/Organisation/Profile/:businessId/:userName/:userId" element={<Profile />} />


      
 <Route path="/User/Organisation/global-signup/:uniqueUrlNumber" element={<Globalsignup />} />
 <Route path="/User/Organisation/Pay/:uniqueUrlNumber" element={<Pay />} />
 <Route path="/User/Organisation/Payment-Success/:uniqueUrlNumber" element={<PaymentSuccess />} />
 <Route path="/User/Organisation/Update-organisation/:businessId" element={<Updateorganisation />} />
 <Route path="/User/Organisation/Renew-plan" element={<Renewplan />} />
 <Route path="/User/Organisation/Creating-Course" element={<CreatingCourse />} />
 <Route path="/User/Organisation/Course/:courseName" element={<CourseDashboard />} />
 <Route path="/User/Organisation/Course/Select-course/:businessId/:userName/:userId" element={<Selectcourse />} />


      <Route path="*" element={<NotFound />} />
      
      </Routes>
  );
}

export default AppRoutes;
