import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { PaystackButton } from 'react-paystack';
import { detectCurrency } from './currencyDetector';

const PaymentPage = () => {
  const { uniqueUrlNumber } = useParams();
  const [teamData, setTeamData] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [country, setCountry] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [organizationEmail, setOrganizationEmail] = useState('');
  const [amount, setAmount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const db = getFirestore();
        const docRef = doc(db, 'Business-Registration', uniqueUrlNumber, 'Onboard-Teams', uniqueUrlNumber);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        setTeamData(data.teamData);
        setSelectedCourses(data.selectedCourses);
        setCountry(data.country);
        setOrganizationName(data.organizationName);
        setOrganizationEmail(data.organizationEmail);
        setAmount(data.amount);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchTeamData();
  }, [uniqueUrlNumber]);

  const handlePaymentSuccess = async (reference) => {
    const db = getFirestore();
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);

    const paymentData = {
      reference: reference.reference,
      dateOfPayment: serverTimestamp(),
      expirationDate: serverTimestamp(),
      organizationName,
      organizationEmail,
      amount,
      purpose: 'Team training on Lecturemeet',
      title: 'Business for team subscription',
    };

    try {
      // Add payment details to PaymentHistory-Business and Onboard-Teams collections
      const paymentHistoryRef = collection(db, 'PaymentHistory-Business');
      const onboardTeamsRef = doc(db, 'Business-Registration', uniqueUrlNumber, 'Onboard-Teams', uniqueUrlNumber);

      await Promise.all([
        addDoc(paymentHistoryRef, paymentData),
        addDoc(collection(onboardTeamsRef, 'PaymentHistory'), paymentData)
      ]);

      // Add course data
      const courseRef = collection(db, 'Business-course');
      const teamPromises = teamData.map(async (member) => {
        const loginId = generateLoginId();
        return addDoc(courseRef, { ...member, loginId });
      });
      await Promise.all(teamPromises);

      // Redirect to Payment Success page with payment details
      navigate(`/User/Organisation/Payment-Success/${uniqueUrlNumber}`, { state: { paymentDetails: { ...paymentData, dateOfPayment: new Date(), expirationDate } } });
    } catch (error) {
      console.error('Error adding document:', error.message);
    }
  };

  // Function to generate a reference number
  const generateReference = () => {
    const characters = '0987653257898575645347864310';
    let result = '';
    for (let i = 0; i < 12; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  // Function to generate a login ID
  const generateLoginId = () => {
    const capitalLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const smallLetters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';

    let loginId = '';
    for (let i = 0; i < 3; i++) {
      loginId += capitalLetters.charAt(Math.floor(Math.random() * capitalLetters.length));
      loginId += smallLetters.charAt(Math.floor(Math.random() * smallLetters.length));
      loginId += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }

    return loginId;
  };

  // Paystack public key
  const publicKey = 'pk_live_9eef972d8706d0d5069ed6f3122f96289654d5c0';

  return (
    <section className="payment-section">
      <h2>Payment Details</h2>
      <div>
        <label>Organization Name:</label>
        <span>{organizationName}</span>
      </div>
      <div>
        <label>Organization Email:</label>
        <span>{organizationEmail}</span>
      </div>
      <div>
        <label>Country:</label>
        <span>{country}</span>
      </div>
      <br />
      <br />
      <div>
        <label className="courses-label">Selected Courses:</label>
        <ul>
          {selectedCourses.map((course, index) => (
            <li key={index}>{course}</li>
          ))}
        </ul>
      </div>

      <br />
      <br />
      <div>
        <label>Team Members:</label>
        <ul>
          {teamData.map((member, index) => (
            <li key={index}>{member.fullName} - {member.email}</li>
          ))}
        </ul>
      </div>
      <div>
        <label>Total Amount: {detectCurrency(country)} {amount.toLocaleString()}</label>
      </div>
      <PaystackButton
        text="Proceed Now"
        reference={generateReference()}
        email={organizationEmail}
        amount={amount * 100}
        publicKey={publicKey}
        currency={detectCurrency(country)}
        onSuccess={(reference) => handlePaymentSuccess(reference)}
        onClose={() => console.log('Payment closed')}
        className="paystack-button"
      />
    </section>
  );
};

export default PaymentPage;
