export const detectCurrency = (country) => {
    switch (country) {
      case 'Nigeria':
        return 'NGN';
      case 'United Kingdom':
        return 'GBP';
      case 'Canada':
        return 'CAD';
      case 'Ghana':
        return 'GHS';
      case 'Rwanda':
        return 'RWF';
      case 'Kenya':
        return 'KES';
      case 'South Africa':
        return 'ZAR';
      case 'Uganda':
        return 'UGX';
      case 'Netherlands':
        return 'EUR';
      case 'Australia':
        return 'AUD';
      default:
        return 'USD'; // Default to USD if country is not found
    }
  };
  