import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import './assets/css/header.css';
import './assets/css/footer.css';
import './assets/css/body.css';
import './assets/css/general.css';
import './assets/css/Dashboard.css';
import './assets/css/Admi.css';
import './assets/css/Texteditor.css';
import './assets/css/Accountreg.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer';
import Routes from './Route';

function App() {
  return (
    <Router>
      <div className="App">
      
        <Routes />
        
        <Footer />
      </div>
    </Router>
  );
}

export default App;
