import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

const Globalsignup = () => {
  const navigate = useNavigate();
  const { uniqueUrlNumber } = useParams();
  const [teamSize, setTeamSize] = useState('');
  const [teamData, setTeamData] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [country, setCountry] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [organizationEmail, setOrganizationEmail] = useState('');
  const [error, setError] = useState('');
  const [amount, setAmount] = useState(0);

  const handleTeamSizeChange = (size) => {
    setTeamSize(size);
    setTeamData(Array.from({ length: size }, (_, index) => ({ email: '', fullName: '' })));
    calculateAmount(size, selectedCourses);
  };

  const handleTeamMemberEmailChange = (index, email) => {
    setTeamData(prevData => prevData.map((item, i) => (i === index ? { ...item, email } : item)));
  };

  const handleTeamMemberFullNameChange = (index, fullName) => {
    setTeamData(prevData => prevData.map((item, i) => (i === index ? { ...item, fullName } : item)));
  };

  const handleCourseChange = (courses) => {
    setSelectedCourses(courses);
    calculateAmount(teamSize, courses);
  };

  const calculateAmount = (size, courses) => {
    const teamAmount = country === 'Nigeria' ? 5000 * parseInt(size) : 5 * parseInt(size);
    const courseAmount = country === 'Nigeria' ? 37000 * courses.length : 37 * courses.length;
    setAmount(teamAmount + courseAmount);
  };

  const handleOnboardTeam = async () => {
    try {
      const db = getFirestore();
      const orgRef = doc(db, 'Business-Registration', uniqueUrlNumber, 'Onboard-Teams', uniqueUrlNumber);
      await setDoc(orgRef, {
        teamData,
        selectedCourses,
        country,
        businessId: uniqueUrlNumber,
        organizationName,
        organizationEmail,
        amount,
      });

      navigate(`/User/Organisation/Pay/${uniqueUrlNumber}?businessId=${uniqueUrlNumber}`, {
        state: {
          teamData,
          selectedCourses,
          country,
          organizationName,
          organizationEmail,
          amount,
        },
      });
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <section className="onboarding-section">
      <h2>Onboard Your Team</h2>
      <label>Organization Name:</label>
      <input type="text" value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} required />
      <label>Organization Email:</label>
      <input type="email" value={organizationEmail} onChange={(e) => setOrganizationEmail(e.target.value)} required />
      <label>Select Country:</label>
      <select value={country} onChange={(e) => setCountry(e.target.value)} required>
        <option value="">Select Country</option>
        <option value="Australia">Australia (AUD)</option>
<option value="Canada">Canada (CAD)</option>
<option value="Ghana">Ghana (GHS)</option>
<option value="Kenya">Kenya (KES)</option>
<option value="Netherlands">Netherlands (EUR)</option>
<option value="Nigeria">Nigeria (NGN)</option>
<option value="Rwanda">Rwanda (RWF)</option>
<option value="South Africa">South Africa (ZAR)</option>
<option value="Uganda">Uganda (UGX)</option>
<option value="United Kingdom">United Kingdom (GBP)</option>
<option value="United States">United States (USD)</option>
        <option value="Other Region">Other Region</option>
      </select>
      
      <label>Select Team Size:</label>
      <select value={teamSize} onChange={(e) => handleTeamSizeChange(e.target.value)}>
        <option value="">Select Team Size</option>
        {[...Array(10).keys()].map((num) => (
          <option key={num} value={num + 1}>{num + 1}</option>
        ))}
      </select>
      {teamData.map((team, index) => (
        <div key={index} className="team-member">
          <input type="email" placeholder="Team Email" value={team.email} onChange={(e) => handleTeamMemberEmailChange(index, e.target.value)} required={index < parseInt(teamSize)} />
          <input type="text" placeholder="Team Full Name" value={team.fullName} onChange={(e) => handleTeamMemberFullNameChange(index, e.target.value)} required={index < parseInt(teamSize)} />
        </div>
      ))}
      <label>Select Courses:</label>
      <select multiple value={selectedCourses} onChange={(e) => handleCourseChange(Array.from(e.target.selectedOptions, option => option.value))}>
        <option value="Compliance in Payment Card Industry Data Security Standard (PCI DSS)">Compliance in Payment Card Industry Data Security Standard (PCI DSS)</option>
        <option value="Introduction to Virtual Reality (VR) and Augmented Reality (AR)">Introduction to Virtual Reality (VR) and Augmented Reality (AR)</option>
        <option value="Anti Money Laundering AML and CFT Compliance Officer">Anti Money Laundering (AML/CFT) Compliance Officer</option>
        <option value="Corporate Law Governance, and Regulatory Policies (CLG)">Corporate Law Governance, and Regulatory Policies (CLG)</option>
        <option value="Data Protection Regulation: GDPR, & Regional Compliance Officer">Data Protection Regulation: GDPR, & Regional Compliance Officer</option>
        <option value="Intellectual Property Law">Intellectual Property Law</option>
        <option value="Business Development and Risk Compliance Officer">Business Development and Risk Compliance Officer</option>
        <option value="Banking and Financial Services Law">Banking and Financial Services Law</option>
        <option value="Taxation Policy & Regulatory compliance">Taxation Policy & Regulatory compliance</option>
  <option value="Mergers and Acquisition Manager">Mergers and Acquisition Manager</option>
  <option value="Insurance Law & Policy (Regulatory framework - Nigeria)">Insurance Law & Policy (Regulatory framework - Nigeria)</option>
  <option value="Human Resource Management and Compliance">Human Resource Management and Compliance</option>
  <option value="Product Management Fundamentals">Product Management </option>
  <option value="Product Marketing Guide">Product Marketing Guide</option>
      </select>
      <label>Total Amount: {country === 'Nigeria' ? `₦${amount.toLocaleString()}` : `$${amount.toLocaleString()}`}</label>

      {error && <div className="error-message">{error}</div>}
      <button onClick={handleOnboardTeam} disabled={!teamSize || teamData.some(item => !item.email || !item.fullName)}>Onboard Team</button>
    </section>
  );
};

export default Globalsignup;
