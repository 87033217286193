import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Lecturemeet.png';

const SignupSection = () => {
  return (
    <section className="signup-section">
      <div className="container">
        <div className="signup-options">
          <div className="logo-container">
          <Link to='/'>
            <img src={logo} alt="LectureMeet Logo" />
            </Link>
          </div>
          <h2>Welcome Back</h2>
          <p>Let's go again</p>
          <div className="signup-links">
            <Link to="/Account/Login/Business-for-teams">Login Team Member</Link>
            <Link to="https://auth.lecturemeet.com/Auth/" target='_blank'>Pay Per Course</Link>
          </div>
          <br />
          <br />
          <div className="login-option">
            <p>Don't have an account ? <Link to="/Account">Create Account</Link></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignupSection;
