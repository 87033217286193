import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

const UserProfile = ({ userId }) => {
  const [user, setUser] = useState(null);
  const [image, setImage] = useState('default-image-url'); // Default image URL

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const db = getFirestore();
        const docRef = doc(db, 'User-Business', userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUser(docSnap.data());
          // Assuming 'image' is a field in the user document
          setImage(docSnap.data().image || 'default-image-url');
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchUserData();
  }, [userId]);

  const handleImageClick = () => {
    // Logic to handle image update
    // This could involve opening a file picker, uploading the new image, and updating the 'image' field in Firestore
    console.log('Update image logic here');
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-profile">
      <div className="user-info">
        <img src={image} alt="Profile" onClick={handleImageClick} style={{ cursor: 'pointer' }} />
        <h3>{user.fullName}</h3>
        <p>Organization: {user.organization}</p>
      </div>
      <div className="courses">
        <h4>Courses Selected:</h4>
        <ul>
          {user.coursesSelected && user.coursesSelected.map((course, index) => (
            <li key={index}>{course}</li>
          ))}
        </ul>
        <h4>Courses Completed:</h4>
        {user.coursesCompleted && user.coursesCompleted.length > 0 ? (
          <ul>
            {user.coursesCompleted.map((course, index) => (
              <li key={index}>{course}</li>
            ))}
          </ul>
        ) : (
          <p>No courses completed yet.</p>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
