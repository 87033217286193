import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import '../../assets/css/Org.css';

const UpdateOrganisation = () => {
  const { businessId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [organizationDetails, setOrganizationDetails] = useState(location.state || {});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!location.state || !location.state.authenticated) {
      navigate('/Account/Login/Admin-flow');
    }
  }, [location.state, navigate]);

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      setLoading(true);
      try {
        const db = getFirestore();
        const orgRef = doc(db, 'Business-Registration', businessId, 'Onboard-Teams', businessId);
        const orgDoc = await getDoc(orgRef);

        if (orgDoc.exists()) {
          setOrganizationDetails(orgDoc.data());
        } else {
          setError('Organization details not found.');
        }
      } catch (error) {
        setError(`Error fetching details: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (!location.state) {
      fetchOrganizationDetails();
    }
  }, [businessId, location.state]);

  const handleUpdateDetails = async () => {
    setLoading(true);
    try {
      const db = getFirestore();
      const orgRef = doc(db, 'Business-Registration', businessId, 'Onboard-Teams', businessId);
      await setDoc(orgRef, {
        ...organizationDetails,
        dateOfPayment: serverTimestamp(),
        expirationYear: new Date().getFullYear() + 1,
      }, { merge: true });

      alert('Details updated successfully.');
    } catch (error) {
      setError(`Error updating details: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleRenewSubscription = () => {
    navigate(`/User/Organisation/global-signup/${businessId}`);
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/Account/Login/Admin-flow');
    } catch (error) {
      setError(`Error logging out: ${error.message}`);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);
    try {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const logoURL = reader.result;
        setOrganizationDetails(prevState => ({
          ...prevState,
          logoURL,
        }));
        const db = getFirestore();
        const orgRef = doc(db, 'Business-Registration', businessId, 'Onboard-Teams', businessId);
        await setDoc(orgRef, { logoURL }, { merge: true });
        alert('Logo updated successfully.');
      };
      reader.readAsDataURL(file);
    } catch (error) {
      setError(`Error uploading file: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    container: {
      width: '100%',
      maxWidth: '1200px',
      margin: '20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    sidebar: {
      width: '200px',
      backgroundColor: '#2c3e50',
      color: 'white',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    sidebarImage: {
      width: '100px',
      marginBottom: '20px',
    },
    sidebarButton: {
      width: '100%',
      margin: '10px 0',
      padding: '10px',
      border: 'none',
      backgroundColor: '#3498db',
      color: 'white',
      cursor: 'pointer',
      borderRadius: '5px',
    },
    mainContent: {
      flexGrow: '1',
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    updateSection: {
      marginBottom: '20px',
    },
    loadingSpinner: {
      display: loading ? 'block' : 'none',
      border: '8px solid #f3f3f3',
      borderRadius: '50%',
      borderTop: '8px solid #3498db',
      width: '60px',
      height: '60px',
      animation: 'spin 1s linear infinite',
    },
    errorMessage: {
      color: 'red',
      marginBottom: '20px',
    },
    detailContainer: {
      marginBottom: '20px',
    },
    detail: {
      margin: '10px 0',
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '5px',
      backgroundColor: '#f9f9f9',
    },
    ul: {
      listStyleType: 'none',
      padding: '0',
    },
    li: {
      margin: '10px 0',
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '5px',
      backgroundColor: '#f9f9f9',
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonDisabled: loading ? { cursor: 'not-allowed', opacity: 0.5 } : {},
  };

  return (
    <div style={styles.container}>
      <div style={styles.sidebar}>
        <img src={organizationDetails.logoURL || 'organization-logo.png'} alt="Organization Logo" style={styles.sidebarImage} />
        <input type="file" onChange={handleFileUpload} style={{ marginBottom: '20px', color: 'white', display: 'none' }} />
        <label htmlFor="file-upload" style={{ ...styles.sidebarButton, ...styles.buttonDisabled }}>Upload Logo</label>
        <button style={styles.sidebarButton} onClick={handleUpdateDetails} disabled={loading}>
          Update Now
        </button>
        <button style={styles.sidebarButton} onClick={handleRenewSubscription} disabled={loading}>
          Renew/Add Teams
        </button>        
        <button style={styles.sidebarButton} onClick={handleLogout} disabled={loading}>
          Logout
        </button>
      </div>
      <div style={styles.mainContent}>
        <section style={styles.updateSection} className='sectioncompany'>
          <h2>Update Organisation Details</h2>
          {error && <div style={styles.errorMessage}>{error}</div>}
          {loading && <div style={styles.loadingSpinner}></div>}
          {organizationDetails && !loading && (
            <div>
              <div style={styles.detailContainer}>
                <p>Organization Name: {organizationDetails.organizationName}</p>
                <p>Organization Email: {organizationDetails.organizationEmail}</p>
                <p>Country: {organizationDetails.country}</p>
                <p>Team Size: {organizationDetails.teamData.length}</p>
                <p>Total Amount: {organizationDetails.amount}</p>
              </div>
              <div style={styles.detailContainer}>
                <p style={styles.detail}><strong>Selected Courses:</strong></p>
                <ul style={styles.ul}>
                  {organizationDetails.selectedCourses.map((course, index) => (
                    <li key={index} style={styles.detail}>{course}</li>
                  ))}
                </ul>
              </div>
              <div style={styles.detailContainer}>
                <h3>Team Members:</h3>
                <ul style={styles.ul}>
                  {organizationDetails.teamData.map((member, index) => (
                    <li key={index} style={styles.li}>
                      <span><strong>Full Name:</strong> {member.fullName}</span>
                      <span><strong>Email:</strong> {member.email}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default UpdateOrganisation;
