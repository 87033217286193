import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import html2canvas from 'html2canvas';
import '../../../assets/css/Certificate.css';
import signature from '../../../assets/images/Signature.png'; 
import Award from '../../../assets/images/Award.png'; 
import logo from '../../../Lecturemeet.png';

const CertificatePage = () => {
    const { businessId, courseName, userName } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { course } = location.state || {};

    const [organization, setOrganization] = useState('');
    const [certificateNumber, setCertificateNumber] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrganizationName = async () => {
            if (!course) return;

            try {
                const db = getFirestore();
                const businessRef = doc(db, 'Business-Registration', businessId, 'Onboard-Teams', businessId);
                const businessSnap = await getDoc(businessRef);

                if (businessSnap.exists()) {
                    setOrganization(businessSnap.data().organizationName);
                } else {
                    setError('Business not found');
                }
            } catch (error) {
                setError('Error fetching organization name');
                console.error('Error fetching organization name: ', error);
            }
        };

        const fetchCertificateNumber = async () => {
            try {
                const db = getFirestore();
                const certificateRef = doc(db, 'Certificate-Business-Team-learners', `${businessId}_${userName}`);
                const certificateSnap = await getDoc(certificateRef);

                if (certificateSnap.exists()) {
                    setCertificateNumber(certificateSnap.data().certificateNumber);
                } else {
                    setError('Certificate not found');
                }
            } catch (error) {
                setError('Error fetching certificate number');
                console.error('Error fetching certificate number: ', error);
            }
        };

        fetchOrganizationName();
        fetchCertificateNumber();
        setLoading(false);
    }, [businessId, course, userName, courseName]);

    const handlePrint = () => {
        window.print();
    };

    const handleDownload = () => {
        const certificateSection = document.querySelector('.certificate-container');
        const buttons = certificateSection.querySelector('.button-container');

        // Temporarily hide the buttons
        buttons.style.display = 'none';

        const options = {
            scrollX: 0,
            scrollY: 0,
            scale: 2,
            windowWidth: certificateSection.offsetWidth,
            windowHeight: certificateSection.offsetHeight,
        };

        html2canvas(certificateSection, options).then(canvas => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = `${userName}_certificate.png`; // Set the download file name
            link.click();

            // Restore the visibility of buttons after capturing the image
            buttons.style.display = 'block';
        }).catch(error => {
            console.error('Error capturing certificate: ', error);

            // Restore the visibility of buttons if an error occurs
            buttons.style.display = 'block';
        });
    };

    const handleGoBack = () => {
        navigate(-1); // Go back to the previous page
    };

    const addSuffixToDay = (date) => {
        if (date > 3 && date < 21) return date + 'th';
        switch (date % 10) {
            case 1: return date + 'st';
            case 2: return date + 'nd';
            case 3: return date + 'rd';
            default: return date + 'th';
        }
    };

    const currentDateObj = new Date();
    const dayWithSuffix = addSuffixToDay(currentDateObj.getDate());
    const month = currentDateObj.toLocaleDateString('en-US', { month: 'long' });
    const year = currentDateObj.getFullYear();
    const currentDate = `${dayWithSuffix} ${month}, ${year}`;

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!course) {
        return <div>No course information available</div>;
    }

    return (
        <div className="certificate-container" id="certificate">
            <div className='Lshapetopleft'></div>
            <img src={logo} alt="LectureMeet Logo" className="logo" />
            <h1>Certificate of Completion</h1>
            <p className="recognition">In recognition of the successful completion of the {courseName} course, 
            <br /> <br /> we are pleased to confirm and award <br /> <br /> you</p>
            <p className="infoname"> {userName} <br />a certificate of program completion this {currentDate}.</p>
            <p className="course-title">Having completed the {course.courseTitle} course and all the learning modules.</p>
            <br />
            <br />
            <div className='Lshapebottomleft'>
                {certificateNumber && <h2>Certificate ID: {certificateNumber}</h2>}
                <p className="organization-name"><strong>Sponsored by:</strong> {organization}</p>
                <p className="businessid"><strong>Business ID:</strong> {businessId}</p> 
            </div>
            <br />
             <div className='awardcert'>
                    <img src={Award} alt='Award' />
                </div>
            <div className='right-section imagesign'>
                <img className='signature' src={signature} alt="Signature" />
                <div className='text'>
                    <hr className='dividerline' />
                    <p>Adaeze E. Chidubem</p>
                    <i> <p>Head of Compliance and Management</p></i>
                </div>
            </div>
            <div className="button-container">
                <button onClick={handlePrint}>Print Certificate</button>
                <button onClick={handleDownload}>Download Certificate</button>
                <button onClick={handleGoBack}>Go Back</button>
            </div>
        </div>
    );
};

export default CertificatePage;
